import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Gerbodyne from './gerbodyne';
const About = () => <div>About</div>;
const Dashboard = () => <div>Dashboard</div>;

const AppRoutes = () => (
    <div>
        <Routes>
            <Route exact path="/" element={<Gerbodyne />} />
            <Route path="/about" element={<About />} />
            <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
    </div>
);

export default AppRoutes;
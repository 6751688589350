import React, { useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'motion/react';
import logo from './gerbodyne_logo.png';
import '@fontsource/press-start-2p';
import { sendContactForm, sendNewsletterSubscription } from './services/emailService';

const GerbodyneCreativeSite = () => {
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start start", "end end"]
  });

  const [contactForm, setContactForm] = useState({ name: '', email: '', message: '' });
  const [newsletterEmail, setNewsletterEmail] = useState('');

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    if (!contactForm.name || !contactForm.email || !contactForm.message) {
      alert('Please fill in all fields');
      return;
    }
    try {
      await sendContactForm(contactForm);
      alert('Message sent! We\'ll get back to you soon.');
      setContactForm({ name: '', email: '', message: '' });
    } catch (error) {
      alert('Failed to send message. Please try again later.');
      console.error('Error sending message:', error);
    }
  };

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    if (!newsletterEmail) {
      alert('Please enter your email');
      return;
    }
    try {
      await sendNewsletterSubscription(newsletterEmail);
      alert('Thanks for subscribing! Check your email for confirmation.');
      setNewsletterEmail('');
    } catch (error) {
      alert('Failed to subscribe. Please try again later.');
      console.error('Error subscribing:', error);
    }
  };

  // Parallax and color transformations
  const backgroundColor = useTransform(
    scrollYProgress,
    [0, 0.2, 0.4, 0.6, 0.8, 1],
    [
      '#000',    // Deep midnight blue
      '#4A148C',    // Rich indigo-purple
      '#2962FF',    // Vibrant electric blue
      '#00BCD4',    // Bright cyan
      '#009688',    // Teal
      '#4CAF50'     // Soft green
    ]
  );

  const logoScale = useTransform(
    scrollYProgress,
    [0, 0.2],
    [1, 0.5]
  );

  const sections = [
    {
      id: 'hero',
      background: 'bg-opacity-10',
      content: (
        <div className="min-h-screen flex flex-col justify-center items-center relative">
          <motion.div 
            ref={scrollRef}
            style={{ backgroundColor }}
            className="min-h-screen relative flex flex-col items-center justify-center overflow-hidden"
          >
            <motion.div 
              style={{ scale: logoScale }}
              className="z-10 mb-12"
            >
              <img 
                src={logo}
                alt="Gerbodyne Logo" 
                className="w-72 h-72 object-contain"
              />
            </motion.div>
      
          </motion.div>
        </div>
      )
    },
    {
      id: 'about',
      background: 'bg-opacity-20',
      content: (
        <div style={{ fontFamily: "'Press Start 2P', cursive" }} className="min-h-screen flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            style={{ fontFamily: "'Press Start 2P', cursive" }}
            className="max-w-3xl p-12 bg-white bg-opacity-70 rounded-xl shadow-lg"
          >
            <h2 style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-2xl font-pixel mb-6 text-red-600">Who We Are</h2>
            <p style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-sm text-gray-700 mb-6">
            Gerbodyne Systems is not your run-of-the-mill game studio. We're less a company and more a chaotic assemblage of imagination engineers, pixel wizards, and narrative alchemists—bound together by the kind of fever dream that makes sensible people slowly back away and reasonable computers spontaneously reboot.
            </p>
            <p style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-sm text-gray-700 mb-6">
            Imagine, if you will, a place where code dances with creativity, where algorithms waltz with absurdity, and where every line of programming is a potential portal to a universe that didn't exist five minutes ago. We don't just make games; we craft bizarre little worlds that exist in the quantum space between reality and pure, unadulterated whimsy.
            </p>
            <p style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-sm text-gray-700">
            Our passion isn't just for storytelling or technological innovation—it's for creating interactive experiences that are equal parts philosophy lecture, fever dream, and unexpected comedy routine. We believe games aren't mere entertainment, but complex thought experiments disguised as fun. They're the modern equivalent of a court jester—telling profound truths while making you laugh, cry, and occasionally question the very nature of reality itself.
            </p>
            <p style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-sm text-gray-700">
            When we say we push boundaries, we don't mean gentle nudging. We mean full-contact, no-holds-barred boundary demolition, complete with metaphorical explosives and a soundtrack of maniacal laughter.
            </p>
          </motion.div>
        </div>
      )
    },
    {
      id: 'projects',
      background: 'bg-opacity-30',
      content: (
        <div style={{ fontFamily: "'Press Start 2P', cursive" }} className="min-h-screen flex flex-col justify-center items-center">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl bg-white bg-opacity-70 p-12 rounded-xl shadow-lg"
          >
            <h2 className="text-3xl font-pixel mb-8 text-red-600">Our Creative Journey</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-2xl font-pixel mb-4 text-gray-800">Current Project</h3>
                <p style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-sm text-gray-700 mb-4">
                Imagine a game so utterly unexpected that it makes traditional game mechanics look like they've been designed by a committee of particularly unimaginative accountants. We can't reveal the details—partly because we enjoy a good mystery, and partly because some secrets are better kept behind the curtain of anticipation.
                </p>
                <p style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-sm text-gray-700">
                What we can say is this: our upcoming title is less a game and more a quantum uncertainty machine. It's designed to surprise you in the way a surprise party might—if the party was hosted by a philosopher, a quantum physicist, and a slightly mischievous AI with a sense of humor.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      )
    },
    {
      id: 'contact',
      background: 'bg-opacity-40',
      content: (
        <div style={{ fontFamily: "'Press Start 2P', cursive" }} className="min-h-screen flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white bg-opacity-70 p-12 rounded-xl shadow-lg max-w-2xl w-full"
          >
            <h2 className="text-3xl font-pixel mb-8 text-red-600 text-center">Join Our Journey</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-base font-pixel mb-4 text-gray-800">Connect With Us</h3>
                <p style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-sm text-gray-700 mb-6">
                  Whether you're a potential collaborator, press inquirer, 
                  or simply curious about our work, we'd love to hear from you.
                </p>
                <form className="space-y-4" onSubmit={handleContactSubmit}>
                  <input 
                    type="text" 
                    placeholder="Name" 
                    value={contactForm.name}
                    onChange={(e) => setContactForm({...contactForm, name: e.target.value})}
                    className="w-full p-3 border rounded-lg"
                  />
                  <input 
                    type="email" 
                    placeholder="Email" 
                    value={contactForm.email}
                    onChange={(e) => setContactForm({...contactForm, email: e.target.value})}
                    className="w-full p-3 border rounded-lg"
                  />
                  <textarea 
                    placeholder="Your Message" 
                    value={contactForm.message}
                    onChange={(e) => setContactForm({...contactForm, message: e.target.value})}
                    className="w-full p-3 border rounded-lg h-32"
                  ></textarea>
                  <button 
                    type="submit"
                    className="w-full bg-red-600 text-white p-3 rounded-lg hover:bg-red-700 transition"
                  >
                    Send Message
                  </button>
                </form>
              </div>
              <div style={{ fontFamily: "'Press Start 2P', cursive" }}>
                <h3 className="text-base font-pixel mb-4 text-gray-800">Stay Informed</h3>
                <p style={{ fontFamily: "'Press Start 2P', cursive" }} className="text-sm text-gray-700 mb-6">
                  Follow our journey and get exclusive updates about our 
                  upcoming projects, behind-the-scenes insights, and 
                  game development philosophy.
                </p>
                <form className="space-y-4" onSubmit={handleNewsletterSubmit}>
                  <input 
                    type="email" 
                    placeholder="Enter your email" 
                    value={newsletterEmail}
                    onChange={(e) => setNewsletterEmail(e.target.value)}
                    className="w-full p-3 border rounded-lg"
                  />
                  <button 
                    type="submit"
                    className="w-full bg-gray-800 text-white p-3 rounded-lg hover:bg-gray-700 transition"
                  >
                    Subscribe to Newsletter
                  </button>
                </form>
              </div>
            </div>
          </motion.div>
        </div>
      )
    }
  ];

  return (
    <motion.div 
      ref={scrollRef}
      style={{ backgroundColor }}
      className="min-h-screen"
    >
      {sections.map((section) => (
        <section 
          key={section.id} 
          className={`relative ${section.background}`}
        >
          {section.content}
        </section>
      ))}
    </motion.div>
  );
};

export default GerbodyneCreativeSite;
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import logo from './logo.png';
import hamburger from './hamburger-menu.png';
import './App.css';
import Routes from './Routes';

export default function BasicExample() {
  setTimeout(() => {
    let mainNav = document.getElementById('js-menu');
    let navBarToggle = document.getElementById('js-navbar-toggle');

    navBarToggle.addEventListener('click', function () {
      mainNav.classList.toggle('active');
    });
  }, 2000);

  return (
    <Router>
      <Routes />
    </Router>
  );
}
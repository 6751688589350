import emailjs from '@emailjs/browser';

// Initialize EmailJS with your user ID
emailjs.init("L-bvpKReSVAdaA3zT"); // Replace with your EmailJS user ID

export const sendContactForm = async (data) => {
  try {
    const response = await emailjs.send(
      "service_e28zc9s", // Replace with your EmailJS service ID
      "template_jkvzzna", // Replace with your EmailJS template ID for contact form
      {
        to_email: "info@gerbodyne.com",
        from_name: data.name,
        from_email: data.email,
        message: data.message,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendNewsletterSubscription = async (email) => {
  try {
    const response = await emailjs.send(
      "service_e28zc9s", // Replace with your EmailJS service ID
      "template_jkvzzna", // Replace with your EmailJS template ID for newsletter
      {
        to_email: "info@gerbodyne.com",
        from_email: email,
        message: "Newsletter subscription",
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
